

  .grid-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: stretch; /* Los componentes se estiran para tener la misma altura */
  }
  
  .left-component {
    flex: 1; /* El componente de la derecha ocupa todo el ancho disponible */
    position: relative;
    overflow: hidden;
  }
  
  .right-component {
    width: 400px; /* El ancho se ajusta automáticamente al contenido */
    height: 400px; /* La altura se ajusta automáticamente a la del componente de la derecha */
    position: relative;
    overflow: hidden;

  }
  .center-component {
    width: 100%; /* El ancho se ajusta automáticamente al contenido */
    max-height: 100vw;
    margin-bottom: 8px; /* La altura se ajusta automáticamente a la del componente de la derecha */
    position: relative;
    overflow: hidden;
    min-height: calc(100vw - 16px);
    border-radius: 10px;
  }
  

  .warning {
    animation: warningAnimation 500ms infinite; /* 2s para un ciclo completo de 1 segundo hacia cada dirección */
  }
  .alert {
    animation: alertAnimation 500ms infinite; /* 2s para un ciclo completo de 1 segundo hacia cada dirección */
  }
  
  @keyframes alertAnimation {
    0% {
      color: red; /* Reemplaza esto por tu color de fondo base */
      opacity: 0.8;
      transform: rotate(10deg);
    }
    50% {
      color: red; /* Reemplaza esto por tu color de fondo base */
      opacity: 1;
      transform: rotate(-10deg);
    }
    100% {
      color: red; /* Reemplaza esto por tu color de fondo base */
      opacity: 0.8;
      transform: rotate(10deg);
    }
    
  }
  
  @keyframes warningAnimation {
    0% {
      color: #ffcd1a; /* Reemplaza esto por tu color de fondo base */
      opacity: 0.8;
      transform: rotate(10deg);
    }
    50% {
      color: #ffcd1a; /* Reemplaza esto por tu color de fondo base */
      opacity: 1;
      transform: rotate(-10deg);
    }
    100% {
      color: #ffcd1a; /* Reemplaza esto por tu color de fondo base */
      opacity: 0.8;
      transform: rotate(10deg);
    }
  }
  
  
  